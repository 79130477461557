import React from "react";
import { Container,  Navbar, } from 'react-bootstrap';
import './Footer.css';

const Footer = () => {

    return( 
      <div className='banner d-flex justify-content-center' >
        <Navbar expand="lg"  data-bs-theme="dark">
        <Container >
        {/* <Navbar.Brand href="#welcome" onClick={toggleUSERNAME}> */}
          <Navbar.Brand href="#footer">   
                &copy; Nathan's Corner 2024         
          </Navbar.Brand>
          
        </Container>
      </Navbar>
      </div>
    );
};

export default Footer;
