import React, { useEffect } from 'react';

const CalendlyWidget = () => {
    useEffect(() => {
        // Create a script element
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = 'https://assets.calendly.com/assets/external/widget.js';

        // Append the script to the document body
        document.body.appendChild(script);

        // Cleanup function to remove the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []); // This effect runs only once on component mount

    useEffect(() => {
        // Initialize Calendly widget after the script is loaded
        if (window.Calendly) {
            window.Calendly.initBadgeWidget({
                url: 'https://calendly.com/jondiaz719/set-a-one-on-one',
                text: 'Let\'s set up a call 🤙🏼',
                color: '#ffffff',
                textColor: '#6b1d33',
                branding: undefined
            });
        }
    }, []); // This effect runs only once on component mount

    return (
        <div className="calendly-widget-container" style={{ position: 'relative', height: '100px' }}>
            {/* Placeholder for the Calendly widget */}
            {/* You can customize the size and position of the widget by adjusting the height and style */}
        </div>
    );
};

export default CalendlyWidget;
