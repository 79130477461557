import React  from "react";
import { Container, Nav, Navbar, NavDropdown, Popover, OverlayTrigger, Button} from 'react-bootstrap';
import './Banner.css';
import WelcomeModal from "../WelcomeModal/WelcomeModal";
import { useGlobalState } from "../../config/routes";
import NavBarIcon from "../../assets/special/favicon_package_v0.16/mstile-150x150_white.png"

const Banner = () => {
  const { username, show, setShow, setOkay} = useGlobalState(); // Access global state and methods

  const handleUserChange = () => {
      setOkay(false)
      localStorage.setItem('imOkay', true);
      localStorage.setItem('username', '');
      setShow(true); // Show the modal
  };

    return( 
      <div className='banner'>
        <Navbar expand="lg" bg="dark" data-bs-theme="dark">
        <Container>
        {/* <Navbar.Brand href="#welcome" onClick={toggleUSERNAME}> */}
            <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={
                        <Popover id="popover-basic">
                            {/* <Popover.Header as="h3">Hi there!</Popover.Header> */}
                            <Popover.Body>Thanks for Visiting~</Popover.Body>
                        </Popover>
                    }>
                      <img
                        src={NavBarIcon}
                        alt="React Bootstrap logo"
                        className="img_banner"
                      />
              </OverlayTrigger>

          <Navbar.Brand href="#welcome" onClick={handleUserChange} >
          
          <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={
                        <Popover id="popover-basic">
                            <Popover.Header as="h3">Hi there!</Popover.Header>
                            <Popover.Body>Not you? just click me~</Popover.Body>
                        </Popover>
                    }>
                        <Button variant='dark'>
                            {(!username || username === "___________") ? "Welcome!" : `Welcome ${username}!`}
                        </Button>
                    </OverlayTrigger>
            
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="./">Home</Nav.Link>
              <Nav.Link href="#whoami">Who Am I?</Nav.Link>
              {/* <Nav.Link href="#link">Portafolio</Nav.Link> */}
              <Nav.Link href="./#biz" >Business</Nav.Link>
              <Nav.Link href="./portfolio">Portfolio</Nav.Link>
              {/* <NavDropdown title="Portafolio" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.3">
                  Current Projects
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                  Future Projects
                </NavDropdown.Item>
              </NavDropdown> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
        {/* Introductory Modal */}
            {/* <WelcomeModal username={username} setShow={setShowModal} setUsername={setUsername}/> */}
            {show && <WelcomeModal />}
      </Navbar>
      </div>
    );
};

export default Banner;
