import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Image, ListGroup, Card} from 'react-bootstrap'
import '../NathansLandingPage/NathanLanding.css';
import './PortafolioSection.css';
import GameTree from '../../assets/img/Projects/game_tree.jpeg';
import Vallactiva from '../../assets/img/Projects/vallactiva_soft_img_1.png';
import ArduinoWatching from '../../assets/img/Projects/video-watching.jpeg';


import Banner from '../Banner/Banner';
import Foot from '../footer_path/Footer';



// import { Helmet } from 'react-helmet';

import { useGlobalState } from '../../config/routes'; // Import the custom hook
import CalendlyWidget from '../Calendly/Calendly';
//nathanscorner.vercel.app

const PortafolioSection = (props) => {
    const { username, setUsername, show, setShow,  imOkay} = useGlobalState(); // Access 

    const [gameTreeDescription, setGameTreeDescription] = useState(`This project implements a game tree-based AI to play a 2D board game. The AI utilizes various data structures, including HashTable, SortedDoublyLinkedList, Stack, Queue, and Deque, to efficiently manage game states, explore potential moves, and optimize performance.
        
        Key Features:
        
        Hash Table: Stores and retrieves game states for quick lookup. Sorted Doubly Linked List: Manages game states in a sorted order for efficient traversal. Stack: Used for depth-first traversal of the game tree. Queue: Used for breadth-first traversal of the game tree (if applicable). Deque: Used for bidirectional traversal when needed. Game Tree: Constructs a tree of possible game states to explore potential moves. Evaluation Function: Scores board states to determine the best move. Overflow Function: Handles gem overflows according to game rules. Improvements:
        
        Optional: Implemented additional features (e.g., undo moves, animations, AI difficulty settings) using the specified data structures.
        
        This project demonstrates the understanding of data structures, and algorithms, and the effective use of various data structures contributes to the overall efficiency and performance of static AIs.
        
        Project developed by: jdiaz-ospina, smodarres-sadeghi, and jbuen
      `);

    const [officeBilboardDescription, setOfficeBilboardDescription] = useState(`A fully developed web-based platform built using Laravel, JS, HTML5, CSS, MySQL, and integrated with hosting, maintenance, and API functionalities. It allows businesses to create profiles, advertise with video ads, and connect with other businesses in the community.

    Key Features:

    User Roles: Multiple user roles for posting, such as business owners, administrators, and moderators.
    Customizable Profiles: Businesses can create detailed profiles with contact information, images, and descriptions.
    Video Advertising: Businesses can upload and display video ads on the platform.
    Premium Ad Placement: Businesses can pay for more prominent ad placements.
    External Advertising: Businesses outside the center can advertise within the platform.
    Community Features: Businesses can connect, network, and participate in discussions.
    Search Functionality: Users can search for businesses by category, location, or keyword.
    Analytics: Businesses can track the performance of their ads and profiles.
    Technical Stack:

    Backend: Laravel framework for PHP, MySQL database for data storage.
    Frontend: HTML5, CSS for structure and styling.
    Hosting: A reliable hosting provider for the platform's infrastructure.
    Maintenance: Regular maintenance and updates to ensure the platform's security and performance.
    API Integration: Integration with third-party APIs (e.g., payment gateways, social media) for additional functionalities.

    Currently displayed in the "Edificio el Castillo, Manizales, Colombia"`);

    const [openCVDescription, setOpenCVDescription] = useState(`Goal: Automate the process of watching videos for rewards.

    Tech Stack: Python, OpenCV, Arduino, IoT

    Process:

    - Screen Capture: Python script records screen.
    - Keyword Detection: Identifies keywords on video.
    -Arduino Control: Sends commands to Arduino.
    - Motor Actuation: Arduino controls motors to mimic user actions.
    - Benefits: Saves time, increases efficiency.`);

    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        const imOkayLocal = localStorage.getItem('imOkay');
        setShow(!storedUsername || !((storedUsername === "___________") && imOkayLocal)); // Set show to true if username is not stored
        setUsername(storedUsername || ''); // Set username if stored or empty string
    }, [imOkay, setShow, setUsername]);



    return (
        <>
            {/* CREATE A FADING ANIMATION, IN AND OUT FROM LEFT TO RIGHT TO SHOW A NEW VIEW */}

            <Banner username={username} show={show} />
            {/* <Button  onClick={toggleVisibility}>Toggle Visibility</Button> */}
            {/* <Loader classList="loader-hide"/> */}
            <div className='content_wrapper' id="home">
            <div id='main_view' className={"main_view"}></div>
            {/* <MainView /> */}
            
            
                <Container>
                    
                <Row className='mb-5'>
                    
                <h1 style={{color: "white"}} id="welcome_title">
                        {/* Previous welcoming message for landing */}
                        {/* {(!username || username === "___________")? "Welcome to my corner" : `Welcome to my corner, ${username}!`} */}
                        {"Nathan's Portfolio"}
                    </h1>
                    <br/>
                </Row>

                    <Row>
                    <Col className="mb-3">
                        <Card className="cardColor forthThird_container" style={{backgroundColor:'rgb(33, 37, 41)', color:'white'}}>
                        <div style={{height:'275px'}}>
                            <Card.Img variant="top" src={GameTree} style={{position:'relative'}}/>
                            </div>
                            <Card.Body>
                                <Card.Title>GameTree Board Game for Algorithm Design</Card.Title>
                                <Card.Text>
                                <textarea
                                className="form-control"
                                id="exampleFormControlTextarea1"
                                rows="3"
                                value={gameTreeDescription}
                                onChange={(e) => setGameTreeDescription(e.target.value)}
                                />
                            
                                </Card.Text>
                            </Card.Body>
                            <ListGroup className="list-group-flush">
                                <ListGroup.Item>Feb 2024 - Apr 2024</ListGroup.Item>
                                <ListGroup.Item>
                                    <b>
                                    Skills: 
                                    </b>
                                    <p>
                                    Problem Solving · Python · Project Management · Algorithm Design · Data Structures
                                    </p>
                                    
                                </ListGroup.Item>
                                <ListGroup.Item >
                                    
                                <div id="get-started-page">
                                    <div className="section1">
                                        <div className="image-container">
                                            <Image                 src="https://media.licdn.com/dms/image/v2/D560BAQE5dmoMPVzvkA/company-logo_100_100/company-logo_100_100/0/1688646665910/seneca_college_logo?e=1735776000&v=beta&t=b7Miann9sVuF8U602AJ4_359gQfvlZWi7CcWXtGls4s"/>
                                        </div>
                                        <div className="associated">
                                            <p>
                                                Associated With Seneca Polytechnic
                                            </p>
                                        </div>
                                        
                                    </div>
                                </div>

                                </ListGroup.Item>
                            </ListGroup>
                            <Card.Body style={{display:'flex', justifyContent:'center'}}>
                                <Card.Link href="https://www.linkedin.com/in/jonathan-diaz0/details/projects/">Project Link</Card.Link>
                            </Card.Body>
                            </Card>
                        </Col>

                        <Col className="mb-3">
                        <Card className="cardColor firstThird_container" style={{backgroundColor:'rgb(33, 37, 41)', color:'white'}}>
                            <div style={{height:'275px'}}>
                            <Card.Img variant="top" src={ArduinoWatching} style={{position:'relative'}}/>
                            </div>
                            
                            <Card.Body>
                                <Card.Title>
                                Arduino-OpenCV-Integration for Automation</Card.Title>
                                <Card.Text>
                                <textarea
                                className="form-control"
                                id="exampleFormControlTextarea1"
                                rows="3"
                                value={openCVDescription}
                                onChange={(e) => setOpenCVDescription(e.target.value)}
                                />
                            
                                </Card.Text>
                            </Card.Body>
                            <ListGroup className="list-group-flush">
                                <ListGroup.Item>Feb 2024 - Apr 2024</ListGroup.Item>
                                <ListGroup.Item>
                                    <b>
                                    Skills: 
                                    </b>
                                    <p>
                                    Arduino · Internet of Things (IoT) · Python · Computer Vision · Problem Solving
                                    </p>
                                    
                                </ListGroup.Item>
                                <ListGroup.Item >
                                    
                                <div id="get-started-page">
                                    <div className="section1">
                                        <div className="image-container">
                                            <Image                 src="https://media.licdn.com/dms/image/v2/D4E0BAQFYg7aaQb59bA/company-logo_100_100/company-logo_100_100/0/1725295210053/universidad_de_caldas_logo?e=1735776000&v=beta&t=tBb0XdR6CrsZQwgHWYFW8_eK0Bi0ofjaGfr2SQwF7VY"/>
                                        </div>
                                        <div className="associated">
                                            <p>
                                                Associated With University of Caldas
                                            </p>
                                        </div>
                                        
                                    </div>
                                </div>

                                </ListGroup.Item>
                            </ListGroup>
                            <Card.Body style={{display:'flex', justifyContent:'center'}}>
                                <Card.Link href="https://www.linkedin.com/in/jonathan-diaz0/details/projects/">Project Link</Card.Link>
                            </Card.Body>
                            </Card>
                        </Col>
                        
                        <Col className="mb-3">
                        <Card className="cardColor thirdThird_container" style={{backgroundColor:'rgb(33, 37, 41)', color:'white'}}>
                        <div style={{height:'275px'}}>
                            <Card.Img variant="top" src={Vallactiva} style={{position:'relative'}}/>
                            </div>
                            <Card.Body>
                                <Card.Title>Vallactiva - Office Billboard</Card.Title>
                                <br/>
                                <Card.Text>
                                <textarea
                                className="form-control"
                                id="exampleFormControlTextarea1"
                                rows="3"
                                value={officeBilboardDescription}
                                onChange={(e) => setOfficeBilboardDescription(e.target.value)}
                                />                            
                                </Card.Text>
                            </Card.Body>
                            <ListGroup className="list-group-flush">
                                <ListGroup.Item>Jan 2022 - Dec 2022</ListGroup.Item>
                                <ListGroup.Item>
                                    <b>
                                    Skills: 
                                    </b>
                                    <p>
                                    Laravel · Programming · Continuous Integration (CI) · Application Programming Interfaces (API)
                                    </p>
                                    
                                </ListGroup.Item>
                                <ListGroup.Item >
                                    
                                <div id="get-started-page">
                                    <div className="section1">
                                        <div className="image-container">
                                            <Image                 src="https://media.licdn.com/dms/image/v2/C4D0BAQFo9AFy74qy5w/company-logo_100_100/company-logo_100_100/0/1630548566171/ases_empresariales_logo?e=1735776000&v=beta&t=DaVEW60S_y5qKjy-BGnHGXYa8kthyJDIlis8A0e-wfE"/>
                                        </div>
                                        <div className="associated">
                                            <p>
                                                Associated With Ases Empresariales S.A.S
                                            </p>
                                        </div>
                                        
                                    </div>
                                </div>

                                </ListGroup.Item>
                            </ListGroup>
                            <Card.Body style={{display:'flex', justifyContent:'center'}}>
                                <Card.Link href="https://www.linkedin.com/in/jonathan-diaz0/details/projects/">Project Link</Card.Link>
                            </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                </Container>

                <CalendlyWidget/>
                
                <Foot />
                
            </div>

            
            
        </>

    );
};

export default PortafolioSection;
