import React from 'react';
import { Col } from "react-bootstrap"
import "./SecondRow.css"

import { useGlobalState } from '../../../config/routes';

const RightLowerPanel = () => {

    const {isBusinessVisible} = useGlobalState();


    return (
        <Col className={(isBusinessVisible ? " " : "") +'col-12 col-sm-4 thirdThird_container'}>
                    <h1>Education</h1>
                    <div className="card border border-danger rounded my-2 edu_card" style={{ backgroundColor: "transparent", color: "white" }}>
                    <div className="row no-gutters">
                        <div className="col-sm-4 d-flex first_education">
                            <a href="https://www.senecacollege.ca/home.html" target="blank" >
                                <img src="https://uploads-ssl.webflow.com/5a57b3d9f98088000123eb4c/5eb9a067b0347957f67c9686_WebRes_IMG_05910-173.jpg" 
                                className="card-img" alt="Seneca College" />
                            </a>
                        </div>
                        <div className="col-sm-8">
                            <div className="card-body p-0 ">
                                <h5 className="card-title my-0">Seneca Colllege (4.0 GPA)</h5>
                                <p className="card-text my-0">Computer Programming & Analysis</p>
                                <p className="card-text">2023-2025</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card border border-light rounded my-2 edu_card" style={{ backgroundColor: "transparent", color: "white" }}>
                    <div className="row no-gutters">
                        <div className="col-sm-4 d-flex second_education">
                            <a href="https://www.ucaldas.edu.co/portal/" target="blank">
                                <img src="https://www.ucaldas.edu.co/portal/wp-content/uploads/2020/05/programas-especiales.png"
                                className="card-img" alt="University of Caldas" />
                            </a>
                        </div>
                        <div className="col-sm-8">
                            <div className="card-body p-0 ">
                                <h5 className="card-title my-0">University of Caldas(Colombia)</h5>
                                <p className="card-text my-0">B.S. Mechatronics Engineering</p>
                                <p className="card-text">2017-2022</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card border border-success rounded my-2 edu_card" style={{ backgroundColor: "transparent", color: "white" }}>
                    <div className="row no-gutters">
                        <div className="col-sm-4 d-flex third_education">
                            <a href="https://www.sena.edu.co/es-co/Paginas/default.aspx" target="blank">
                                <img src="https://forbes.co/_next/image?url=https%3A%2F%2Fcdn.forbes.co%2F2020%2F09%2FSena-Guaviare-foto-Sena-scaled.jpg%3Fv%3D25601707&w=1920&q=75" 
                                className="card-img" alt="SENA" />
                            </a>
                        </div>
                        <div className="col-sm-8">
                            <div className="card-body p-0 ">
                                <h5 className="card-title my-0">SENA(Colombia)</h5>
                                <p className="card-text my-0">Software Programming</p>
                                <p className="card-text">2019</p>
                            </div>
                        </div>
                    </div>
                </div>
        </Col>

    );
};

export default RightLowerPanel;
