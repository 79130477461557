import React, {useEffect} from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartShopping } from '@fortawesome/free-solid-svg-icons'; // Import the faHouse icon

import { Col, Carousel, Popover, OverlayTrigger, Button} from "react-bootstrap"
import DoubleX from "../../assets/img/Partnerships/DoubleX.png";
import XSMuscleMultiplierBundle from "../../assets/img/Partnerships/XS-Muscle-Multiplier.png";
import SleepGummies from "../../assets/img/Partnerships/Sleep_Gummies.png";
import XSVarietyPack from "../../assets/img/Partnerships/XS-Variety-Pack.png";

import { useGlobalState } from '../../config/routes';

// import CalendlyConsultation from '../Calendly/CalendlyConsultation';


const LeftUpperCarousel = (props) => {

    const {isBusinessVisible} = useGlobalState();
    

    return (
        // ADD VIEW COUNT
            <Col className={(isBusinessVisible ? "business-visible " : "non-business-not-visible ") + 'secondThird_container front_padding col-12 col-sm-4 order-md-3 d-flex flex-column align-items-center'} >
                    <div disabled className={(isBusinessVisible ? "d-flex btn btn-dark btn-outline-light " : "") + "icon_containers"}>
                        <h2 style={{ paddingRight: '15px'}}>All Time Favorites!</h2>
                        {/* <svg className="disapearing bi bi-eye" style={{color: 'white'}} xmlns="http://www.w3.org/4000/svg"  width="10" fill="currentColor"  viewBox="0 0 16 16"> <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" fill="white"></path> <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" fill="white"></path> </svg> */}
                        <FontAwesomeIcon icon={faCartShopping} className='disapearing'/> {/* Use the imported icon */}
                        

                    </div>
                    <Carousel>
                    <Carousel.Item interval={4000}>
                    
                        <a href='https://www.amway.ca/en_CA/myshop/NathansCorner' target='blank'>
                        <img src={DoubleX} alt="First slide" className='products-carousel'/>
                        </a>
                        
                    </Carousel.Item>
                    <Carousel.Item interval={4000}>
                        <a href='https://www.amway.ca/en_CA/myshop/NathansCorner' target='blank'>
                            <img src={XSMuscleMultiplierBundle} alt="Second slide" className='products-carousel'/>
                        </a>
                        
                    </Carousel.Item>
                    <Carousel.Item interval={4000}>
                        <a href='https://www.amway.ca/en_CA/myshop/NathansCorner' target='blank'>
                            <img src={SleepGummies} alt="Third slide" className='products-carousel'/>
                        </a>

                    </Carousel.Item>
                    <Carousel.Item interval={4000}>
                        <a href='https://www.amway.ca/en_CA/myshop/NathansCorner' target='blank'>
                            <img src={XSVarietyPack} alt="Forth slide" className='products-carousel'/>
                        </a>

                    </Carousel.Item>
                    </Carousel>
                    
                    <div className='d-flex justify-content-around'>
                    {/* <Button type="submit" className="btn-dark">
                                <b className='p-2'>Order</b>
                                <img className="pt-1" src="https://cdn-icons-png.flaticon.com/512/5095/5095640.png" style={{ transform: "rotate(180deg)" }} width="20px" alt="download_button" />
                            </Button> */}
                            <Button type="" className="btn-dark border-white" >
                                <a href='https://calendly.com/jondiaz719/set-a-one-on-one' target='blank' className="btn-dark" style={{textDecoration: 'none'}}>
                                    <b className='p-2' style={{ color: 'white'}}>Book a Free Consultation!</b>
                                    <img className="" src="https://cdn-icons-png.flaticon.com/512/1940/1940847.png" style={{ transform: "rotate(360deg)", filter: "invert(100%)" }} width="20px" alt="download_button" />
                                </a>
                            </Button>
                            {/* HELLO</CalendlyConsultation> */}
                    </div>

                </Col>

    );
};

export default LeftUpperCarousel;
