import React, { useState, createContext, useContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NathanLanding from "../components/NathansLandingPage/NathanLanding";
import PortafolioSection from "../components/PortafolioSection/PortafolioSection";

// Create Global Context
const GlobalContext = createContext();

// Define Routes Component
const RoutesComponent = () => {
  const [username, setUsername] = useState("inDevelopment");
  const [imOkay, setOkay] = useState(localStorage.getItem("imOkay"));
  const [isBusinessVisible, setBusinessVisibility] = useState(window.location.hash === "#biz");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [show, setShow] = useState(false);

  // Define Global State Object
  const globalState = {
    username,
    setUsername,
    isAuthenticated,
    setIsAuthenticated,
    show,
    setShow,
    imOkay,
    setOkay,
    isBusinessVisible,
    setBusinessVisibility
  };
  
  return (
    <GlobalContext.Provider value={globalState}>
      <Router>
        <Routes>
          <Route path="/" element={<NathanLanding />} />
          <Route path="/portfolio" element={<PortafolioSection />} />
        </Routes>
      </Router>
    </GlobalContext.Provider>
  );
};

// Export Global State Hook and Routes Component
export const useGlobalState = () => useContext(GlobalContext);
export default RoutesComponent;
