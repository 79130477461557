import React from 'react';
import Nathan_Logo from '../../assets/special/favicon_package_v0.16/mstile-150x150_white.png';
import '../loader/loader.css';
// import ReactDOM from 'react-dom';

class Loader extends React.Component
{
  //constructor() { }


  componentDidMount() 
  {

    // let loader_div = document.querySelector('#loader-page');
    
    //Pantalla de Carga
    // window.onload = function() {
    //   let loader_div = document.querySelector('#loader-page');
    //   // window.addEventListener('touchstart', handleTouch);
    //   // loader_div.classList.remove('loader-hide');
    //   setTimeout(function () { 
    //     loader_div.classList.add('loader-hide');
    //   }, 1000);
    // };

    // const handleTouch = () => {
    //   loader_div.classList.add('loader-hide');
    // };
  }
  componentWillUnmount() {
    let loader_div = document.querySelector('#loader-page');
    setTimeout(function () { 
      loader_div.classList.add('loader-hide');
    }, 2000);

  }
  
  render()
  {
    return <div id="loader-page" className="loader-page"><img id="img-loader" src={Nathan_Logo} alt='loader'/></div>
  }
  
}
export default Loader;
