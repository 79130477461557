import React, {useEffect } from 'react';
import './App.css';
// import Loader from './components/loader/loader';
import  RoutesComponent  from './config/routes';



import Nathan_Logo from '../src/assets/special/favicon_package_v0.16/mstile-150x150_white.png';
import './components/loader/loader.css';
import Loader from './components/loader/loader';

import { SpeedInsights } from "@vercel/speed-insights/react"

const App = () => {

  // const [faviconUrl, setFaviconUrl] = useState(
  //   "https://media.geeksforgeeks.org/wp-content/cdn-uploads/gfg_favicon.png"
  //       );
  useEffect(() => {


    //Patch for vercel app
    let hash_value = window.location.hash;
    let path = window.location.pathname.split('/')[1];
    if(window.location.href.includes("https://nathanscorner.vercel.app")){
      window.location.href = "https://www.nathanscorner.in/" + path + hash_value;  
      
    }

    // window.onload = function() {
    //   let loader_div = document.querySelector('#loader-page');
    //   setTimeout(function () { 
    //     loader_div.classList.add('loader-hide');
    //   }, 1000);
    // };

    return () => {
      // componentWillUnmount logic here
    };
  }, []);

  return (
    <div className='hidden-scrollbar' id="home">
      {/* <Favicon url={faviconUrl} /> */}
      <RoutesComponent/>
      {/* <div id="loader-page" className="loader-page"><img id="img-loader" src={Nathan_Logo} alt='loader'/></div> */}
      {/* <Loader /> */}
      
    </div>
  );
}

export default App;
