import React, { useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeaf} from '@fortawesome/free-solid-svg-icons'; // Import the faHouse icon

import { Col, Carousel} from "react-bootstrap";
// import DoubleX from "../../assets/img/Partnerships/DoubleX.png";
// import XSProteinPods from "../../assets/img/Partnerships/XS-Protein-Pods.png";
// import XSSummit from "../../assets/img/Partnerships/XS-Summit.png";
// import XSVarietyPack from "../../assets/img/Partnerships/XS-Variety-Pack.png";

import XSVideo_1 from "../../assets/video/XS_video_pineapple_guava(1080p).mp4";
import Nutrilite_1 from "../../assets/video/Nutrilite Organics - Vitamins and Supplements _ Amway.mp4";

import "../../../node_modules/video-react/dist/video-react.css";
import { Player, BigPlayButton, ControlBar} from 'video-react';
import { useGlobalState } from '../../config/routes';


const RightUpperCarousel = () => {

    const playerRefXS = useRef(null);
    const playerRefNutri = useRef(null);

    const isBusinessVisible = useGlobalState()

 
    

    return (
        <Col className={(isBusinessVisible ? "business-visible " : "non-business-not-visible ") + 'secondThird_container front_padding col-12 col-md-4 order-md-1'}>
            <div disabled className={(isBusinessVisible ? "d-flex btn btn-dark btn-outline-light " : "") + 'icon_containers'} style={{marginBottom:'10px'}}>
                        <h2 style={{paddingRight: '15px'}}>Exclusive Partnerships!</h2>
                        {/* <svg className="disapearing bi bi-eye" style={{color: 'white'}} xmlns="http://www.w3.org/3000/svg"  width="10" fill="currentColor"  viewBox="0 0 16 16"> <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" fill="white"></path> <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" fill="white"></path> </svg> */}
                        <FontAwesomeIcon icon={faLeaf} className='disapearing'/> {/* Use the imported icon */}
                        

                    </div>
                    <Carousel>
                    <Carousel.Item interval={20000}>
                        {/* <a href='https://www.amway.ca/en_CA/myshop/NathansCorner'> */}
                        <Player
                        ref={playerRefXS}
                        autoPlay
                        muted
                        loop
                        // poster="XSProteinPods"
                        src={XSVideo_1}>

                            <BigPlayButton position="center" hidden/>
                            <ControlBar autoHide={true} disableDefaultControls={true} muted={true} className="my-class" />
                        </Player>
                        
                    </Carousel.Item>
                    <Carousel.Item interval={20000}>
                        {/* <a href='https://www.amway.ca/en_CA/myshop/NathansCorner'> */}
                        <Player
                        ref={playerRefNutri}
                        autoPlay
                        muted
                        loop
                        // poster="XSProteinPods"
                        src={Nutrilite_1}
                        className="rounded">
                        
                            <BigPlayButton position="center" hidden/>
                            <ControlBar autoHide={true} disableDefaultControls={true} muted={true} className="my-class" />
                        </Player>
                        
                    </Carousel.Item>
                    
                    </Carousel>
                    <p className='fx_i1 inner_quote'>"Be not as you are, but as you should be~"</p>
                    
                    
                </Col>

    );

    
};



export default RightUpperCarousel;
