import React from 'react';
import { Col, OverlayTrigger, Popover} from "react-bootstrap"

import Me_Biz_prof from '../../assets/img/Me_Biz_prof.png';
import linkedIn from '../../assets/img/linkedIn-icon.png';
import gmailIcon from '../../assets/img/gmail-icon.png';
import gitHubIcon from "../../assets/img/Github_logo.png";
import igIcon from "../../assets/img/ig-icon.png";
import amIcon from "../../assets/img/Amway_logo.png";
import { useGlobalState } from '../../config/routes';



const MainDescription = () => {

    // const {isBusinessVisible} = useGlobalState();
    const { isBusinessVisible} = useGlobalState();


    return (
        <Col className='quote_container front_padding col-sm order-md-2' >
                <OverlayTrigger trigger={['hover', 'focus']} placement="top-end" overlay={
                    <Popover id="popover-basic" 
                    style={{backgroundColor:'transparent', borderStyle: "solid", borderWidth: "1px", 
                    borderColor:"white", margin: "auto", }}>
                        {/* <Popover.Header as="h3">Hi there!</Popover.Header> */}
                        <Popover.Body style={{color: 'white', fontFamily: "Borel, cursive", paddingBottom:"5px"}}>Thanks for Visiting~</Popover.Body>
                    </Popover>
                }>
                    <img className="fx_i1 prof_pic" src={Me_Biz_prof} alt="me"/>
              </OverlayTrigger>
              {isBusinessVisible === false ?  

                <em className="fx_i1 inner_quote_1">Mechatronics Engineer | Full-Stack Developer | Business Builder</em>
                :
                <em className="fx_i1 inner_quote_1">Mechatronics Engineer | Business Owner | Organic Consultant</em>
              
              }
            
            <div className="social_container pb-3"> 
                {/* SOCIAL ICONS SHOUDNT MESS WITH MY PROFILE PHOTO */}
                <a target="blank" href="https://www.instagram.com/athan.d.59/"> <img className="social_icon s1" src={igIcon} width="40px" alt="ig"/></a>
                <a target="blank" href="https://www.linkedin.com/in/jonathan-d-667932122/"> <img className="social_icon s2" src={linkedIn} width="40px" alt="look_me_up_link"/></a>
                <a target="blank" href="https://www.amway.ca/en_CA/myshop/NathansCorner"> <img className="social_icon s3" src={amIcon} width="40px" alt="my_shop"/></a>
                <a target="blank" href="mailto:jondiaz719@gmail.com"> <img className="social_icon s4" src={gmailIcon} width="40px" alt="e-mail_me"/></a>
                <a target="blank" href="https://github.com/Athan-D719"> <img className="social_icon s5" src={gitHubIcon} width="40px" alt="my_github"/></a>

            </div>
        </Col>

    );
};

export default MainDescription;
