import React, { useEffect, useState } from 'react';
import { Container, Row, Button } from 'react-bootstrap'
import './NathanLanding.css';
// import './Banner.css';


import Banner from '../Banner/Banner';
import Foot from '../footer_path/Footer';
import MainDescription from './MeDescription';
import RightUpperCarousel from './RightUpperCarousel';
import RightUpperPanel from './RightUpperPanel';
import LeftUpperCarousel from './LeftUpperCarousel';
import LeftUpperPanel from './LeftUpperPanel'

import Loader from '../loader/loader';

import CenterColumn from './SecondRow/CenterColumn';
import LeftLowerPanel from './SecondRow/LeftLowerPanel';
import RightLowerPanel from './SecondRow/RightLowerPanel';




// import { Helmet } from 'react-helmet';

import { useGlobalState } from '../../config/routes'; // Import the custom hook
import CalendlyWidget from '../Calendly/Calendly';
import { isVisible } from '@testing-library/user-event/dist/utils';
//nathanscorner.vercel.app

const NathanLanding = (props) => {
    const { username, setUsername, show, setShow,  imOkay, isBusinessVisible, setBusinessVisibility} = useGlobalState(); // Access global state and methods
    // const [isBusinessVisible, setBusinessVisibility] = useState(true);
    const [visibilityAnimation, setVisibilityAnimation] = useState(false)

    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        const imOkayLocal = localStorage.getItem('imOkay');
        setShow(!storedUsername || !((storedUsername === "___________") && imOkayLocal)); // Set show to true if username is not stored
        setUsername(storedUsername || ''); // Set username if stored or empty string
    }, [imOkay, setShow, setUsername]);

    const toggleVisibility = () => {

        var to_be_removed = ""
        to_be_removed = (!visibilityAnimation ?  "non-business-visible" : "business-visible");
        
        console.log(to_be_removed);
        console.log(visibilityAnimation);
        var elements = new Array;
        elements = document.getElementsByClassName(to_be_removed);
        Array.from(elements).forEach(function(element){
            element.classList.remove("secondThird_container");
            element.classList.add('secondThird_container_BV');
        },80);

        setVisibilityAnimation(true);
        
        setTimeout(() => {
            setBusinessVisibility(!isBusinessVisible);
            setVisibilityAnimation(false);
        }, 800);
    };

    useEffect(() => {
        const hashChangeListener = () => {
            var to_be_removed = ""
            to_be_removed = (!visibilityAnimation ?  "non-business-visible" : "business-visible");
            
            
            
            console.log(to_be_removed);
            console.log(visibilityAnimation);

            var main_view = document.getElementById("main_view");
            // let loader_div = document.querySelector('#loader-page');
            // loader_div.classList.remove('loader-hide');
            
            
            if (window.location.hash === '#biz') {
                // setBusinessVisibility(true);
                to_be_removed = "non-business-visible";

                
                main_view.classList.add("main_blurr_in");
                
                var elements = new Array;
                elements = document.getElementsByClassName(to_be_removed);
                Array.from(elements).forEach(function(element){
                    element.classList.remove("secondThird_container");
                    element.classList.add('secondThird_container_BV');
                },80);
    
                setTimeout(() => {

                    main_view.classList.remove("main_view");
                    main_view.classList.remove("main_blurr_in");
                    main_view.classList.add("biz_view");
                    main_view.classList.add("main_blurr_out");

                    setBusinessVisibility(true);
                    setVisibilityAnimation(false);
                }, 800);

                // loader_div.classList.add('loader-hide');
    

            } else {
                // setBusinessVisibility(false);

                main_view.classList.add("main_blurr_in");
                to_be_removed = ("business-visible");
                
                var elements = new Array;
                elements = document.getElementsByClassName(to_be_removed);
                Array.from(elements).forEach(function(element){

                    main_view.classList.remove("main_view");
                    main_view.classList.remove("main_blurr_in");
                    main_view.classList.add("biz_view");
                    main_view.classList.add("main_blurr_out");

                    element.classList.remove("secondThird_container");
                    element.classList.add('secondThird_container_BV');
                },80);
    
                setTimeout(() => {
                    setBusinessVisibility(false);
                    setVisibilityAnimation(false);
                }, 800);

                // loader_div.classList.add('loader-hide');

            }

            main_view.classList.remove("main_blurr_out");
            // loader_div.classList.add('loader-hide');
            setVisibilityAnimation(true);


        };

        // Add event listener for hash change
        window.addEventListener('hashchange', hashChangeListener);

        // Remove event listener on component unmount
        return () => {
            window.removeEventListener('hashchange', hashChangeListener);
        };
    }, [setBusinessVisibility]);




    const loaded = () => {
        // console.log(startDateTime);
    };

    // IDEA INTROCUCTORY MODAL VIDEO
    // const video_stop = () => {
    //     document.getElementById('expo_intro').pause();
    // };

    return (
        <>
            {/* CREATE A FADING ANIMATION, IN AND OUT FROM LEFT TO RIGHT TO SHOW A NEW VIEW */}

            <Banner username={username} show={show} />
            {/* <Button  onClick={toggleVisibility}>Toggle Visibility</Button> */}
            {/* <Loader classList="loader-hide"/> */}
            <div className='content_wrapper' id="home">
            <div id='main_view' className={!isBusinessVisible ? "main_view" : "biz_view"} onLoad={loaded()}></div>
            
            
            {/* <MainView /> */}
            <br/>
                <Container>
                    <h1 style={{color: "white"}} id="welcome_title">
                        {/* Previous welcoming message for landing */}
                        {/* {(!username || username === "___________")? "Welcome to my corner" : `Welcome to my corner, ${username}!`} */}
                        {"“The best way to predict the future, is to make it”  — Bryan Tracy"}
                    </h1>
                    <Row className='d-flex align-items-center mt-2'>
                        <MainDescription />

                        {isBusinessVisible ? (
                            <>
                                <RightUpperCarousel />
                                <LeftUpperCarousel />
                            </>
                        ) : (
                            <>
                                <RightUpperPanel />
                                <LeftUpperPanel />
                            </>
                        )}
                        
                        
                    </Row>

                    {/* /////////////////////////////////////////////////////////////////// */}
                    <hr/>

                    <div className="second_third">
                        <Row id="desc_second" >
                        {/* className={isBusinessVisible ? "encapsulate_biz_secondRow" : ""} */}
                        <hr/>
                            <LeftLowerPanel  />
                            <CenterColumn />
                            <RightLowerPanel />
                        <hr/>
                        </Row>
                        
                    </div>

                    {/* //////////////////////////////////////////////////////////////////////////// */}
                    
                </Container>

                <CalendlyWidget/>
                
                <Foot />
                
            </div>
            
        </>

    );
};

export default NathanLanding;
