import React from 'react';
import { Col } from "react-bootstrap"

import { useGlobalState } from '../../config/routes';

const RightUpperPanel = () => {

    const {isBusinessVisible} = useGlobalState();

    return (
        <Col className={(!isBusinessVisible ? "non-business-visible " : "business-visible ") + 'secondThird_container front_padding col-md-4 col-sm-12 order-md-1 d-flex flex-column'}> 
            <div className="icon_containers mb-3 d-flex align-items-center">

            <h3 style={{ textAlign: "center" }}>Self Accountability Chart</h3>
            <svg id="svg_live" style={{ color: "white", paddingLeft: "10px" }} xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="disapearing bi bi-activity" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2Z" fill="white"></path>
            </svg>
            </div>

            <iframe id="notion_chart" src="https://chartbase.so/embed/7c0ba9b8-197c-4022-949b-6426c8c6f90d" width="100%"  frameBorder="0" allowFullScreen title='notion_chart'/>
            {/* <iframe src="https://grid.is/embed/untitled-AMqiH8d7SLKwj8jZZR5TwA?p=yCHBY1r2wI&width=full" width="100%" height="300" frameborder="0" data-document-id="00caa21f-c77b-48b2-b08f-c8d9651e53c0"></iframe>
            <script type="text/javascript" src="https://grid.is/static/embed/v1/script.js"></script> */}

            <p className="fx_i1 inner_quote">"Be not as you are, but as you should be~"</p>
        </Col>

    );
};

export default RightUpperPanel;
