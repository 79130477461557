import React from 'react';
import { Col, Button } from "react-bootstrap"
import "./SecondRow.css"

import { useGlobalState } from '../../../config/routes';

// import CleanResume from "../../../assets/docs/Clean Resume.pdf"

const CenterColumn = () => {

    const { isBusinessVisible } = useGlobalState();

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = "https://athan-d719.github.io/src/Clean%20Resume.pdf"; // Change the path accordingly
        link.download = 'Clean Resume.pdf'; // Set the desired file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    //Updated Date
    // const [updatedDate, setStartDateTime] = useState('');

    // useEffect(() => {
    //     setStartDateTime(new Date(Date.now()));
    // });

    return (
        <Col className={(isBusinessVisible ? " " : "") +'col-12 col-sm-4 firstThird_container'} >
            <h1>Basic Information</h1>
            <div className="columns_grid">
                <p><b>AGE: </b>24</p>
                <p><b>EMAIL: </b>jondiaz719@gmail.com</p>
                <p><b>LANGUAGE: </b>English, Spanish, French</p>
                <p><b>CURRENT RESIDENCE: </b>Canada</p>
                <p><b>AVAILABLE: </b>To Work</p>
                <p><b>UPDATED ON:</b> 2024-05-05</p>
            </div>

            {/* <div className='d-flex justify-content-around'>
                            <Button type="button" className="btn-dark" onClick={handleDownload} >
                                    <b className='p-2' style={{ color: 'white' }} >Resume</b>
                                    <img className="pt-1" src="https://cdn-icons-png.flaticon.com/512/5095/5095640.png"  width="20px" alt="download_button" />

                            </Button>

            </div> */}
        </Col>

    );
};

export default CenterColumn;
