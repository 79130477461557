import React from 'react';
import { Col} from "react-bootstrap"
import "./SecondRow.css"

import { useGlobalState } from '../../../config/routes';

const LeftLowerPanel = () => {

    const {isBusinessVisible} = useGlobalState();



    return (
        // ADD VIEW COUNT
            <Col className={(isBusinessVisible ? " " : "") +'col-12 col-sm-4 forthThird_container mb-3'} id="whoami">
                <h1>Professional Summary</h1>
                <p>As an enthusiastic international student and professional with a background in customer service, administrative assistance, programming, and problem-solving. I like creating positive customer experiences and executing high-priority tasks, demonstrating assertiveness, and problem-solving in fast-paced environments. Excellent interpersonal and communication skills for resolving conflicts. Thrilled to work in a collaborative environment embracing learning opportunities while eager to contribute to the company's worth through warm service and a positive attitude.</p>

            </Col>

    );
};

export default LeftLowerPanel;
