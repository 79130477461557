import React from 'react';
import { Col, Form, Button, Row} from "react-bootstrap"
import Resume from "../../assets/docs/Clean Resume.pdf";

import { useGlobalState } from '../../config/routes';

const LeftUpperPanel = () => {

    const {isBusinessVisible} = useGlobalState();

    return (
        // ADD VIEW COUNT
            <Col  className={(!isBusinessVisible ? "non-business-visible " : "business-visible ") + 'secondThird_container front_padding col-sm order-md-3 d-flex flex-column align-items-center'} >
                    <div className="icon_containers">
                        <h2 style={{marginBottom:'0px', paddingRight: '15px'}}>Reach out</h2>
                        <svg className="disapearing bi bi-eye" style={{color: 'white'}} xmlns="http://www.w3.org/2000/svg"  width="10" fill="currentColor"  viewBox="0 0 16 16"> <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" fill="white"></path> <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" fill="white"></path> </svg>
                        

                    </div>
                    {/* <Form className="border border-light rounded p-3 mt-3" action="mailto:jondiaz@gmail.com" method="post" encType="text/plain">
                        <Form.Group controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Your Name" />
                        </Form.Group>
                        <Form.Group controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder="Your Email" />
                            <Form.Text className="text-muted">We'll never share your email with anyone else.</Form.Text>
                        </Form.Group>
                        <Form.Group controlId="body">
                            <Form.Label>Contact Reason</Form.Label>
                            <Form.Control as="textarea" rows={3} required placeholder="Reason" />
                        </Form.Group>
                        <Row className="pt-4 pb-0">
                            <Col xs={4}>
                                <Form.Check type="checkbox" id="hiring" label="Hiring" />
                            </Col>
                            <Col xs={4}>
                                <Form.Check type="checkbox" id="business" label="Business" />
                            </Col>
                            <Col xs={4}>
                                <Form.Check type="checkbox" id="other" label="Other" />
                            </Col>
                        </Row>
                        <Row>
                        <div className="d-flex justify-content-around pt-2" >
                            <Button type="submit" className="btn-dark">
                                <b className='p-2'>Contact</b>
                                <img className="pt-1" src="https://cdn-icons-png.flaticon.com/512/5095/5095640.png" style={{ transform: "rotate(180deg)" }} width="20px" alt="download_button" />
                            </Button>
                            <Button type="" className="btn-dark" onClick={() => window.open({Resume})}>
                                <b className='p-2'>Resume</b>
                                <img className="pt-1" src="https://cdn-icons-png.flaticon.com/512/5095/5095640.png" style={{ transform: "rotate(180deg)" }} width="20px" alt="download_button" />
                            </Button>
                        </div>

                        </Row>
                    </Form>  */}
                    {/* <!-- Calendly inline widget begin --> */}
                    <div className="calendly-inline-widget border border-light rounded" id="calendlyInline" data-url="https://calendly.com/jondiaz719/set-a-one-on-one?hide_event_type_details=1&hide_gdpr_banner=1" style={{backgroundColor: "white" ,minWidth:"320px", height:"400px"}}></div>
                    {/* <!-- Calendly inline widget end --> */}
                </Col>

    );
};

export default LeftUpperPanel;
